import React, { useState } from 'react';
import './Header.css';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);

    const handleMouseEnter = () => {
        setIsFadingOut(false);
        setDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsFadingOut(true); // Start fade-out
        setTimeout(() => {
            setDropdownOpen(false); // Only hide after fading out
        }, 300); // Match this duration with CSS transition
    };

    return (
        <header className="header">
            <div className="logo-title-container">
                <Link to="/" className="nav-link">
                    <img src={logo} alt="Logo" className="profile-image" />
                </Link>
                <Link to="/" className="nav-link">
                    <h1>Spencer's Desk</h1>
                </Link>
            </div>
            <nav className="nav-buttons">
                <div
                    className="projects-dropdown"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Link to="/projects"><button>Projects</button></Link>
                    <div className={`dropdown ${isDropdownOpen ? (isFadingOut ? 'fade-out' : 'show') : ''}`}>
                        <Link to="/projects/3meloD"><button>3MeloD</button></Link>
                        <Link to="/projects/project2"><button>Project 2</button></Link>
                        <Link to="/projects/project3"><button>Project 3</button></Link>
                    </div>
                </div>
                <button>About</button>
                <button>Contact</button>
            </nav>
        </header>
    );
}

export default Header;
