import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import NotFound from './pages/NotFound';
import Header from './components/Header'; // Import the Header component
import ThreeMeloDPage from './pages/ThreeMeloDPage';

const App: React.FC = () => {
    return (
        <>
            <Header /> {/* Render the Header component here */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/3meloD" element={<ThreeMeloDPage />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default App;
