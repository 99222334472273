import React, { useEffect, useState } from 'react';
import Select from 'react-select';

interface Printer {
    id: number;
    printerName: string;
    xAng: number;
    yAng: number;
    zAng: number;
    xRpr: number;
    yRpr: number;
    zRpr: number;
    xDim: number;
    yDim: number;
    zDim: number;
}

interface Song {
    id: number;
    songName: string;
    songPath: string;
}

const ThreeMeloDPage: React.FC = () => {
    const [printers, setPrinters] = useState<Printer[]>([]);
    const [songs, setSongs] = useState<Song[]>([]);
    const [selectedPrinter, setSelectedPrinter] = useState<Printer | null>(null);
    const [selectedSong, setSelectedSong] = useState<Song | null>(null);
    const [overrides, setOverrides] = useState<{ [key: string]: number | null }>({});
    const [, setError] = useState<string | null>(null);


    useEffect(() => {
        const fetchPrinters = async () => {
            try {
                const response = await fetch('https://spencersdesk.com/api/printers');
                if (!response.ok) throw new Error('Failed to fetch printers');
                const data = await response.json();
                setPrinters(data);
            } catch (err) {
                setError((err as Error).message);
            }
        };

        const fetchSongs = async () => {
            try {
                const response = await fetch('https://spencersdesk.com/api/songs');
                if (!response.ok) throw new Error('Failed to fetch songs');
                const data = await response.json();
                setSongs(data);
            } catch (err) {
                setError((err as Error).message);
            }
        };

        fetchPrinters();
        fetchSongs();
    }, []);

    const handleOverrideChange = (key: string, value: string) => {
        setOverrides((prevOverrides) => ({
            ...prevOverrides,
            [key]: value === '' ? null : parseFloat(value), // Set to null if empty
        }));
    };

    const run3MeloD = async () => {
        if (selectedPrinter && selectedSong) {
            const bodyData = {
                printerId: selectedPrinter.id,
                songId: selectedSong.id,
                overrides: Object.fromEntries(
                    Object.entries(overrides).filter(([, value]) => value !== null)
                ), // Only include non-null overrides
            };

            try {
                const response = await fetch('https://spencersdesk.com/api/run-3meloD', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(bodyData),
                });

                if (!response.ok) {
                    throw new Error('Failed to run 3MeloD');
                }

                // Create a blob from the response
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                // Create a link element for downloading
                const a = document.createElement('a');
                a.href = url;
                a.download = 'output.gcode'; // Specify the name for the downloaded file
                document.body.appendChild(a);
                a.click();
                a.remove(); // Clean up the link element
                window.URL.revokeObjectURL(url); // Free up memory

            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            alert("Please select both a printer and a song.");
        }
    };



    return (
        <div>
            <h2>Select Printer and Song</h2>
            <label htmlFor="printerDropdown">Select Printer:</label>
            <Select
                id="printerDropdown"
                options={printers.map(printer => ({ value: printer.id, label: printer.printerName }))}
                onChange={(option) => setSelectedPrinter(printers.find(p => p.id === option?.value) || null)}
                placeholder="-- Choose a Printer --"
            />

            <label htmlFor="songDropdown">Select Song:</label>
            <Select
                id="songDropdown"
                options={songs.map(song => ({ value: song.id, label: song.songName }))}
                onChange={(option) => setSelectedSong(songs.find(s => s.id === option?.value) || null)}
                placeholder="-- Choose a Song --"
            />

            <h3>Override Printer Parameters</h3>
            {['xAng', 'yAng', 'zAng', 'xRpr', 'yRpr', 'zRpr', 'xDim', 'yDim', 'zDim'].map((param) => (
                <div key={param}>
                    <label htmlFor={param}>{param}:</label>
                    <input
                        type="number"
                        id={param}
                        value={overrides[param] ?? ''}
                        onChange={(e) => handleOverrideChange(param, e.target.value)}
                    />
                </div>
            ))}

            <button onClick={run3MeloD}>Run 3MeloD</button>
        </div>
    );
};

export default ThreeMeloDPage;
