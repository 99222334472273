import React from 'react';
import './Home.css';

const Home: React.FC = () => {
    return (
        <div className="home-container">
            <h1>Welcome to Spencer's Desk!</h1>
            <p>Here, you'll find my latest projects...</p>
        </div>
    );
};

export default Home;
