import React from 'react';
import './Projects.css'; // Optional: create a CSS file for styles

const Projects: React.FC = () => {
    return (
        <div className="projects-container">
            <h1>My Projects</h1>
            <p>
                Welcome to the projects page! Here you can find details about my projects,
                including 3MeloD and other exciting endeavors.
            </p>
            <h2>Featured Project: 3MeloD</h2>
            <p>
                3MeloD is a unique musical experience that combines various sounds and melodies.
                Stay tuned for more updates!
            </p>
        </div>
    );
};

export default Projects;
